import { getExchangeRate } from '@/api/repositories/exchange-rate-repository';

export const getExchangeRateForCurrency = async (
  currency: string,
  successCallback: Function,
  failCallback: Function
) => {
  try {
    const rate = await getExchangeRate(currency);
    successCallback && successCallback(rate);
  } catch (e) {
    console.error(e);
    failCallback();
  }
};
