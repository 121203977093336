'use client'

import { useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { recordInitialVisit } from "./actions/actions";
import { campaignQueryParametersArray, cookieNames } from "./constants/constants";
import { doesCookieExist, getCookie, setCookie } from "./helpers/cookie-helpers";

const CookieComponent = () => {
    const searchParams = useSearchParams();

    const registerSessionCookie = (forceChange: boolean = false) => {
        if(!doesCookieExist(cookieNames.sessionId) || forceChange){
            setCookie(cookieNames.sessionId, Date.now().toString())
        }
    }

    const registerCampaignCookie = () => {
        // check if the campaign parameter exists in the url
        const campaignParameter =  campaignQueryParametersArray.find((parameter:string) => searchParams.has(parameter));

        if(campaignParameter){
            // get the campaging parameter
            const campaignId = searchParams.get(campaignParameter);
            const campaignCookie =  getCookie(cookieNames.campaignId)
            const campaignParamCookie =  getCookie(cookieNames.campaignParam)
            // the new cookie should be registered once we have changed the campaing id or the campaign parameter
            const registerCookie = campaignParamCookie !== campaignParameter || campaignCookie !== campaignId
            
            if(campaignId && registerCookie){
                // set it as cookie for later use
                setCookie(cookieNames.campaignId, campaignId)
                setCookie(cookieNames.campaignParam, campaignParameter)
                registerSessionCookie(true);
                // send the initial visit
                recordInitialVisit(campaignId, campaignParameter, Number(getCookie(cookieNames.sessionId)));
            }
        }
    }

    useEffect(()=>{
        registerSessionCookie();
        registerCampaignCookie();
    },[])

    return null
}

export default CookieComponent;