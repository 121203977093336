'use client';

import React, { createContext, useState, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';

type Severity = 'success' | 'info' | 'warning' | 'error';

type SnackbarContextType = {
  openSnackbar: (message: string, severity: Severity) => void;
};

export const SnackbarContext = createContext<SnackbarContextType>({
  openSnackbar: () => {},
});

function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const openSnackbar = useCallback((message: string, severity: Severity) => {
    setSnackbarData({ open: true, message, severity });
  }, []);

  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarData((prev) => ({ ...prev, open: false, message: '' }));
    },
    []
  );

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarData.open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={snackbarData.severity as AlertProps['severity']}
        >
          {snackbarData.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

export default SnackbarProvider;
