import {
  createTheme,
  ThemeOptions,
  responsiveFontSizes,
} from '@mui/material/styles';

import {Poppins} from 'next/font/google';
import {Roboto} from 'next/font/google';

import colors from './colors';
import {TypographyOptions} from '@mui/material/styles/createTypography';

const poppins = Poppins({
  weight: ['300', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-poppins',
});

const roboto = Roboto({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-roboto',
});

declare module '@mui/material/styles' {
  interface Theme {
    sizes: {
      sidebarWidth: number;
    };
    typography: TypographyOptions;
  }

  interface ThemeOptions {
    sizes?: {
      sidebarWidth: number;
    };
  }

  //Additional Typography Variants
  interface TypographyVariants {
    bodyMedium: React.CSSProperties;
    bodySmallRegular: React.CSSProperties;
    bodyRegular: React.CSSProperties;
    bodyLargeRegular: React.CSSProperties;
    bodyExtraSmall: React.CSSProperties;
    titleMedium: React.CSSProperties;
    titleSemibold: React.CSSProperties;
    h4Medium: React.CSSProperties;
    h4Semibold: React.CSSProperties;
    display1: React.CSSProperties;
    titleT3Medium: React.CSSProperties;
    titleT4Medium: React.CSSProperties;
    bodySmallMedium: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    bodyMedium: React.CSSProperties;
    bodySmallRegular: React.CSSProperties;
    bodyRegular: React.CSSProperties;
    bodyLargeRegular: React.CSSProperties;
    bodyExtraSmall: React.CSSProperties;
    titleMedium: React.CSSProperties;
    titleSemibold: React.CSSProperties;
    h4Medium: React.CSSProperties;
    h4Semibold: React.CSSProperties;
    display1: React.CSSProperties;
    titleT3Medium: React.CSSProperties;
    titleT4Medium: React.CSSProperties;
    bodySmallMedium: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    bodyMedium: true;
    bodySmallRegular: true;
    bodyRegular: true;
    bodyLargeRegular: true;
    bodyExtraSmall: true;
    titleMedium: true;
    titleSemibold: true;
    h4Medium: true;
    h4Semibold: true;
    display1: true;
    titleT3Medium: true;
    titleT4Medium: true;
    bodySmallMedium: true;
  }
}

let defaultTheme = createTheme(
  {
    shape: {
      borderRadius: 8,
    },
    typography: {
      fontFamily: roboto.style.fontFamily,

      h1: {
        fontSize: 66,
        fontWeight: 600,
        fontFamily: poppins.style.fontFamily,
      },
      h2: {
        fontSize: 44,
        fontFamily: poppins.style.fontFamily,
        fontWeight: 700,
        '@media (max-width:600px)': {
          fontSize: '44px',
        },
        '@media (max-width:480px)': {
          fontSize: '32px',
        },
        '@media (max-width:400px)': {
          fontSize: '24px',
        },
      },
      h3: {
        fontSize: 28,
        fontFamily: poppins.style.fontFamily,
      },
      h4: {
        fontSize: 24,
        fontFamily: poppins.style.fontFamily,
      },
      h4Medium: {
        fontSize: 32,
        fontFamily: poppins.style.fontFamily,
        fontWeight: 500,
      },
      h4Semibold: {
        fontSize: 32,
        fontWeight: 600,
        fontFamily: poppins.style.fontFamily,
      },
      h5: {
        fontSize: 22,
        fontFamily: poppins.style.fontFamily,
      },
      h6: {
        fontSize: 18,
        fontFamily: poppins.style.fontFamily,
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 14,
      },
      body1: {
        fontSize: 16,
      },
      body2: {
        fontSize: 14,
      },
      bodyExtraSmall: {
        fontSize: 12,
      },
      bodyMedium: {
        fontSize: 18,
        fontWeight: 600,
      },
      bodySmallRegular: {
        fontSize: 14,
        lineHeight: 2,
      },
      bodyRegular: {
        fontSize: 18,
        fontWeight: 400,
        lineHeight: '24px',
        '@media (max-width:600px)': {
          fontSize: '16px',
          lineHeight: '22px',
        },
      } as React.CSSProperties,
      bodyLargeRegular: {
        fontSize: 20,
        lineHeight: 1,
      },
      titleT3Medium: {
        fontSize: 22,
        fontWeight: 500,
      },
      titleT4Medium: {
        fontSize: 24,
        fontWeight: 500,
        lineHeight: 1.25,
      },
      bodySmallMedium: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.5,
      },
      titleMedium: {
        fontSize: 28,
        fontWeight: 500,
      },
      titleSemibold: {
        fontSize: 32,
        fontWeight: 600,
        '@media (max-width:600px)': {
          fontSize: '20px',
        },
      } as React.CSSProperties,
      display1: {
        fontSize: 64,
        fontWeight: 600,
      },
    },
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary.main,
        light: colors.secondary.light,
        contrastText: colors.secondary.outline,
      },
      tertiary: colors.tertiary,
      highlight: colors.highlight,
      mediumBlue: colors.mediumBlue,
      lightBlue: colors.lightBlue,
      placeholderPurple: colors.placeholderPurple,
      sidebarActive: colors.sidebarActive,
      lightBorder: colors.lightBorder,
      profileHeadingDivider: colors.profileHeadingDivider,
      infoLabel: colors.infoLabel,
      dark: colors.dark,
      darkCardText: colors.darkCardText,
      lightBlueBackground: colors.lightBlueBackground,
      darkBlueIcon: colors.darkBlueIcon,
      disabledIcon: colors.disabledIcon,
      chipBg: {
        active: colors.chipBg.active,
        activeText: colors.chipBg.activeText,
        inactive: colors.chipBg.inactive,
      },
      myBackground: {
        main: colors.background.main,
        appBar: colors.background.appBar,
        actionBtns: colors.background.actionBtns,
        secondary: colors.background.secondary,
      },
      lightBackground: colors.lightBackground,
      cardBorder: {
        gray: colors.cardBorder.gray,
      },
      inactiveLanguageTab: colors.inactiveLanguageTab,
      scheduleCardBorder: colors.scheduleCardBorder,
      scheduleCardActiveBg: colors.scheduleCardActiveBg,
      scheduleCardDetailsBorder: colors.scheduleCardDetailsBorder,
      scheduleCardHoverBg: colors.scheduleCardHoverBg,
      scheduleCapacityCardBg: colors.scheduleCapacityCardBg,
      disabledScheduleCardText: colors.disabledScheduleCardText,
      onSurface: colors.onSurface,
      webSecondary: colors.webSecondary,
      webSecondaryContainer: colors.webSecondaryContainer,
      additionalBackground: colors.additionalBackground,
      outlineVariantHigh: colors.outlineVariantHigh,
      surface: colors.surface,
      secondaryButton: colors.secondaryButton,
      outlineVariant: colors.outlineVariant,
      onBackgroundVariant: colors.onBackgroundVariant,
      surfaceVariant: colors.surfaceVariant,
      tertiaryRating: colors.tertiaryRating,
      discountedPrice: colors.discountedPrice,
      secondaryContainer: colors.secondaryContainer,
      additionalSuccess: colors.additionalSuccess,
      additionalError: colors.additionalError,
      neutral: colors.neutral,
      scheduleSectionBg: colors.scheduleSectionBg,
      passengerTransferCard: colors.passengerTransferCard,
    },
    sizes: {
      sidebarWidth: 300,
    },
  },
  {} satisfies ThemeOptions
);

defaultTheme = responsiveFontSizes(defaultTheme);

export default defaultTheme;
