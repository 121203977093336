const campaignParametersNames = {
    facebook:'fbclid',
    googleAds:'ga',
    print:'print',
    media:'med'
}

export const campaignParametersSources = {
    [campaignParametersNames.facebook]:'Facebook',
    [campaignParametersNames.googleAds]:'Google',
    [campaignParametersNames.print]:'Print',
    [campaignParametersNames.media]:'Media',
}

export const campaignQueryParametersArray:string[] = [
    campaignParametersNames.facebook,
    campaignParametersNames.googleAds,
    campaignParametersNames.print,
    campaignParametersNames.media
]

export const cookieNames = {
    campaignId:'campaignId',
    sessionId:'sessionId',
    campaignParam:'campaignParam'
} 