import { httpClient } from "@/api/httpClient"
import { generateQueryParams } from "@/helpers/helpers";

const recordInitialVisit = async (campaignId:string, source:string, sessionId: number):Promise<string> => {
   const res = await httpClient.post('/app/record-initial-visit',{
        campaignId,
        source,
        sessionId
    });
    return res.data
}

export const getSearchTracking = async (queryParam:any): Promise<any> => {
    const response = await httpClient.get(`/app/get-search-tracking${generateQueryParams(queryParam)}`);
    return response.data;
};


export default { recordInitialVisit, getSearchTracking };