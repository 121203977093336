import Cookies from 'js-cookie'
// package documentation is located at: https://github.com/js-cookie/js-cookie

const setCookie = (cookieName:string, cookieValue:string, cookieDuration:number | undefined = undefined) => {
    Cookies.set(cookieName, cookieValue, {expires: cookieDuration})
}

const getCookie = (cookieName:string) => {
    return Cookies.get(cookieName)
}

const doesCookieExist = (cookieName:string):boolean => {
    const cookie = Cookies.get(cookieName)
    return  cookie !== undefined; 
}

export {setCookie, getCookie, doesCookieExist }