'use client';

import { useCurrencySlice } from '@/store/store';
import { useEffect } from 'react';
import { getExchangeRateForCurrency } from './actions';
import { currencies } from '@/utils/currency';

const CurrencyRateComponent = () => {
  const { currency, setRate, reset } = useCurrencySlice();

  const rateFetchedSuccessfully = (rate: number) => {
    setRate(rate);
  };

  const rateFetchFailed = () => {
    reset();
  };

  const fetchRate = async () => {
    if (currency.value === currencies.euro) {
      setRate(1);
    } else {
      await getExchangeRateForCurrency(
        currency.value,
        rateFetchedSuccessfully,
        rateFetchFailed
      );
    }
  };

  useEffect(() => {
    fetchRate();
  }, [currency.value]);

  return null;
};

export default CurrencyRateComponent;
