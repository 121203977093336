import trackingRepository from '@/api/repositories/trackingRepository'
import { campaignParametersSources } from '../constants/constants';

export const recordInitialVisit = async (campaignId:string, campaignParameter: string, sessionId: number):Promise<string | null> => {
   try {
    const source = campaignParametersSources[campaignParameter]
    const data = await trackingRepository.recordInitialVisit(campaignId, source, sessionId);
    return data;
   }
   catch(e){
    console.error(e)
    return null;
   }
}