'use client';

import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {NextAppDirEmotionCacheProvider} from 'tss-react/next/appDir';

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import muiTheme from '@/theme/muiTheme';

const MuiThemeRegistry = ({children}: {children: React.ReactNode}) => {
  return (
    <>
      <CssBaseline />
      <NextAppDirEmotionCacheProvider options={{key: 'mui'}}>
        <ThemeProvider theme={muiTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {children}
          </LocalizationProvider>
        </ThemeProvider>
      </NextAppDirEmotionCacheProvider>
    </>
  );
};

export default MuiThemeRegistry;
