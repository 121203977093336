import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/[locale]/(web)/component/app-currency-rate/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/[locale]/(web)/component/AppCookieComponet/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/globals.css");
import(/* webpackMode: "eager" */ "/usr/src/app/src/lib/MuiThemeRegistry.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/providers/LanguageOptionsProvider.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/providers/NextAuthProvider.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/providers/ReactQueryClientProvider.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/providers/SnackbarProvider.tsx")